.crp-basics-group {
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 40px;
}

.crp-basics-group-title {
  margin-left: 20px;
  color: maroon;
}

.crp-basics-paragraph {
  font-size: 1.1em;
}

.crp-basics-paragraph a {
  color: maroon;
}

.crp-basics-paragraph a{
    color:maroon;
}

.ant-collapse-header-text {
  font-weight: bold;
}

.embed-responsive-item_basics {
  width: 80%;
  height: 60vh;
  margin: 0 auto;
  display: block;
}

.embed-responsive-item {
  width: 100%;
  height: 100vh;
}

.crp-basics-photo {
  border-radius: 10px;
  z-index: 1;
}

.crp-basics-photo-label-left {
  position: absolute;
  left: 10px;
  bottom: 5px;
  color: white;
  z-index: 10;
}

.crp-basics-photo-label-right {
  position: absolute;
  right: 10px;
  bottom: 5px;
  color: white;
  z-index: 10;
}

.externel-link {
  color: maroon !important;
}

.availablePractices_mh {
  max-height: 400px;
  overflow-y: auto;
  scrollbar-color: #5d1625 #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  display: none;
}

.bolder {
  font-weight: bold;
}

.highlight {
  background-color: yellow;
}
